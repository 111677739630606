import React, { FC } from 'react'
import { useLocation } from 'react-router-dom'
import { hasGroups, hasRoles, hasSingleOrganisation } from '../services/token'
import {
  IMPORT,
  SEARCH,
  SEARCH_UNJUSTIFIED,
  IMPORT_REVIEW,
  AUDIT_SEARCH,
  MI_VIEW,
  BULK_EXPORT,
  BULK_EXPORT_REVIEW,
  BULK_SEARCH,
  RCRC_CONSUMER
} from '../constants/roles'
import { SUPPORT } from '../constants/groups'
import GovUKNavLink from './govuk/GovUKNavLink'
import { useAuthentication } from '../hooks/authentication'
import routing from '../services/routing'
import { RouteState } from '../types/routeType'

const bulkExportMatchingPaths = [
  '/bulk-export/history',
  '/bulk-export/justification',
  '/bulk-export/organisations',
  '/bulk-export/filters',
  '/bulk-export/preview',
  '/bulk-export/finished'
]

const uploadMatchingPaths = [
  '/upload/file-type',
  '/upload/file-select',
  '/upload/file-confirmation',
  '/upload/upload-progress',
  '/upload/upload-finished',
  /^\/upload\/history(.*)/i
]

const searchMatchingPaths = [
  '/search',
  /^\/pass-holder\/(.*)/i,
  /^\/pass-holder\/(.*)\/person/i,
  /^\/pass-holder\/(.*)\/contact/i,
  /^\/pass-holder\/(.*)\/clearance/i,
  /^\/pass-holder\/(.*)\/role/i,
  /^\/pass-holder\/(.*)\/pass/i
]

const bulkSearchMatchingPaths = [
  '/bulk-search',
  '/bulk-search/justification',
  '/bulk-search/file-select',
  '/bulk-search/file-confirm',
  '/bulk-search/file-uploaded',
  '/bulk-search/file-progress',
  /^\/pass-holder\/(.*)/i,
  /^\/pass-holder\/(.*)\/person/i,
  /^\/pass-holder\/(.*)\/contact/i,
  /^\/pass-holder\/(.*)\/clearance/i,
  /^\/pass-holder\/(.*)\/role/i,
  /^\/pass-holder\/(.*)\/pass/i
]

const savedProfilesMatchingPaths = [
  '/saved-profiles'
]

const auditsMatchingPaths = [
  '/audits/search'
]

const reportsMatchingPaths = [
  '/reports/search'
]

const accountActivityPaths = [
  '/account-activity'
]

const statusMatchingPaths = [
  '/status'
]

const repeatCrcMatchingPaths = [
  '/repeatcrc'
]

const Navigation: FC = () => {

  const { tokenParsed } = useAuthentication()

  const { pathname: currentPath, state } = useLocation()
  const { from } = state as RouteState || {}

  return (
    <div className='app-pane__nav'>
      <nav className='app-navigation govuk-clearfix'>
        <ul className='app-navigation__list'>
          {
            ((hasRoles(tokenParsed, IMPORT) && hasSingleOrganisation(tokenParsed)) || hasRoles(tokenParsed, IMPORT_REVIEW)) &&
            <GovUKNavLink isCurrent={routing.isRouteActive(currentPath, uploadMatchingPaths)} path='/upload/history' title='Uploads' />
          }
          {
            hasRoles(tokenParsed, SEARCH, SEARCH_UNJUSTIFIED) &&
            <GovUKNavLink isCurrent={routing.isRouteActive(currentPath, searchMatchingPaths, from, 'search')} path='/search' title='Search' />
          }
          {
            hasRoles(tokenParsed, BULK_SEARCH) &&
            <GovUKNavLink isCurrent={routing.isRouteActive(currentPath, bulkSearchMatchingPaths, from, 'bulk-search')} path='/bulk-search' title='Bulk Search' />
          }
          {
            hasRoles(tokenParsed, SEARCH, SEARCH_UNJUSTIFIED) &&
            <GovUKNavLink isCurrent={routing.isRouteActive(currentPath, savedProfilesMatchingPaths)} path='/saved-profiles' title='Saved Profiles' />
          }
          {
            hasRoles(tokenParsed, AUDIT_SEARCH) &&
            <GovUKNavLink isCurrent={routing.isRouteActive(currentPath, auditsMatchingPaths)} path='/audits/search' title='Search Audit' />
          }
          {
            hasRoles(tokenParsed, MI_VIEW) &&
            <GovUKNavLink isCurrent={routing.isRouteActive(currentPath, reportsMatchingPaths)} path='/reports/search' title='Reports' />
          }
          {
            (hasRoles(tokenParsed, BULK_EXPORT) || hasRoles(tokenParsed, BULK_EXPORT_REVIEW)) &&
            <GovUKNavLink isCurrent={routing.isRouteActive(currentPath, bulkExportMatchingPaths)} path='/bulk-export/history' title='Bulk Export' />
          }
          {
            hasRoles(tokenParsed, RCRC_CONSUMER) &&
            <GovUKNavLink isCurrent={routing.isRouteActive(currentPath, repeatCrcMatchingPaths)} path='/repeatcrc' title='Repeat CRC' />
          }
          {
            hasGroups(tokenParsed, SUPPORT) &&
            <GovUKNavLink isCurrent={routing.isRouteActive(currentPath, statusMatchingPaths)} path='/status' title='Status' />
          }
          {
            hasRoles(tokenParsed, MI_VIEW) && <GovUKNavLink isCurrent={routing.isRouteActive(currentPath, accountActivityPaths)} path='/account-activity' title='Account Activity' />
          }
        </ul>
      </nav>
    </div>
  )
}

export default Navigation
