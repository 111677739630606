import axios from 'axios'
import fileSaver from 'file-saver'
import queryString from 'query-string'
import { ID_ADMIN, SEARCH_USER } from '../../constants/groups'
import response from '../../services/response'
import { IKeycloakToken, hasGroups } from '../../services/token'
import { Pass } from '../../types/PassHolder'
import { ApiSuccessResult, DataSourceType, Filter, IReferenceDataSummary, OrganisationConfig, PageRequest, PageResult, PassHolderSearchSummary, PassHolderSummary, UserOrganisationSummary } from '../../types/api'
import { HelpContexts } from '../../constants/helpContexts'
import _ from 'lodash'

interface PassHolderQueryRequest {
  justification?: string
}

interface PassHolderLinkedProfilesQueryRequest extends PageRequest {
  justification?: string
  matchFields: string[]
}

interface PassHolderLinkedProfilesCompareRequest {
  justification?: string
}

interface PassHolderStatus {
  text: string
  colour: string
}

export const PASS_ACTIVE_COLOUR = 'green'
export const PASS_INACTIVE_COLOUR = 'red'
export const PASS_DEFAULT_COLOUR = 'blue'

type PassActive = boolean | undefined | null
type PassCardStatus = string | undefined | null
type PassCardType = string | undefined | null

const getPassStatusColour = (passActive: PassActive) => {
  if (passActive === true) {
    return PASS_ACTIVE_COLOUR
  }
  if (passActive === false) {
    return PASS_INACTIVE_COLOUR
  }
  return PASS_DEFAULT_COLOUR
}

const getPassHolderStatusSuffix = (active: PassActive, cardStatus: PassCardStatus) => {
  if (cardStatus && cardStatus.trim()) {
    return cardStatus.trim().toLowerCase()
  }
  if (active === true) {
    return 'active'
  }
  if (active === false) {
    return 'inactive'
  }
  return null
}

export type MaybeArray<T> = T | T[]

export function ensureIsArray<T>(items?: MaybeArray<T>): T[] {
  if (!items) {
    return []
  }

  return Array.isArray(items) ? items : [items]
}

const requestPassHolder = async (passHolderId: string, justification?: string) => {
  const query: PassHolderQueryRequest = {}

  if (justification) {
    query.justification = justification
  }

  const result = await axios.get<ApiSuccessResult<PassHolderSummary>>(`/api/v1/pass-holders/${encodeURIComponent(passHolderId)}?${queryString.stringify(query)}`)
  return result.data
}

const exportPassHolder = async (passHolderId: string, justification?: string) => {
  const query: PassHolderQueryRequest = {}

  if (justification) {
    query.justification = justification
  }

  const url = `/api/v1/pass-holders/${encodeURIComponent(passHolderId)}/export?${queryString.stringify(query)}`

  const res = await axios.get(url, { responseType: 'arraybuffer' })
  const contentType = res.headers['content-type']

  const fileName = response.getFilenameFromContentDisposition(res, `Pass Holder ${passHolderId}.pdf`)

  const blob = new Blob([res.data], { type: contentType })

  fileSaver.saveAs(blob, fileName)
}

const requestLinkedProfiles = async (passHolderId: string, justification?: string, page?: number, pageSize?: number, filters?: Filter) => {
  const query: PassHolderLinkedProfilesQueryRequest = {
    matchFields: [],
    page,
    pageSize
  }

  if (justification) {
    query.justification = justification
  }

  const url = `/api/v1/pass-holders/${encodeURIComponent(passHolderId)}/linked-profiles/search?${queryString.stringify(query)}`
  const response = await axios.post<PageResult<PassHolderSearchSummary>>(url, {
    filter: filters
  })
  return response.data
}

const requestCompare = async (passHolderId: string, comparingPassHolderId: string, justification?: string) => {
  const query: PassHolderLinkedProfilesCompareRequest = {
  }

  if (justification) {
    query.justification = justification
  }

  const url = `/api/v1/pass-holders/${encodeURIComponent(passHolderId)}/linked-profiles/${encodeURIComponent(comparingPassHolderId)}?${queryString.stringify(query)}`
  const response = await axios.get(url)
  return response.data
}


const getPassHolderStatus = (
  sourceType: DataSourceType,
  active: PassActive,
  cardStatus: PassCardStatus,
  cardType: PassCardType
): PassHolderStatus => {

  let text = ''
  let colour = PASS_DEFAULT_COLOUR

  if (sourceType === 'AIC_APPLICATION') {
    const statusPrefix = 'AIC application'
    text = cardType && cardType.trim() !== '' ? `${statusPrefix} - ${cardType.trim()}` : statusPrefix
  }
  else if (sourceType && sourceType.trim().length > 0) {

    let statusPrefix = ''

    switch(sourceType) {
      case 'AIR_CARGO_CLEARANCE': {
        statusPrefix = `AIR CARGO CLEARANCE`
        break;
      }
      case 'AV_SEC_INSTRUCTOR': {
        statusPrefix = `AvSec instructor`
        break;
      }
      case 'AV_SEC_VALIDATOR': {
        statusPrefix = `AvSec validator`
        break;
      }
      case 'PORT_PASS': {
        statusPrefix = `Port pass`
        break;
      }
      default: {
        statusPrefix = `${sourceType} pass`
        break;
      }
    }

    const statusSuffix = getPassHolderStatusSuffix(active, cardStatus)

    colour = getPassStatusColour(active)
    text = statusSuffix !== null ? `${statusPrefix} - ${statusSuffix}` : statusPrefix
  }

  return {
    text,
    colour
  }
}

export const contextHelpAvailable = (helpContext: HelpContexts | undefined, pass: Pass, tokenParsed: IKeycloakToken | undefined, orgs: UserOrganisationSummary[] | null, configs: IReferenceDataSummary | null): boolean => {
  if (!tokenParsed) {
    return false
  }

  const contextualHelp = getContextualHelp(pass, tokenParsed, orgs, configs)

  switch (helpContext) {
    case HelpContexts.CARD_FORMAT_INFO:
      return (contextualHelp?.card_format?.cards?.length || 0) > 0
    case HelpContexts.ACCESS_INFO:
      return (contextualHelp?.access_area?.areas?.length || 0) > 0
    case HelpContexts.ISSUE_DATE_INFO:
      return !!contextualHelp?.issue_date?.description;
    case HelpContexts.TOOLS_OF_TRADE_INFO:
      return true
  }

  return false
}

const configMerger = (typeConfigValue: any, orgSpecificConfig: any): any => {
  if (_.isUndefined(typeConfigValue)) {
    return orgSpecificConfig
  }

  if (Array.isArray(typeConfigValue)) {
    if (orgSpecificConfig.length > 0) {
      return orgSpecificConfig
    }
    return typeConfigValue
  }

  if (typeof typeConfigValue === 'object') {
    return _.assignWith({}, typeConfigValue, orgSpecificConfig, configMerger)
  }

  if (typeof typeConfigValue === 'string') {
    if (orgSpecificConfig.length > 0) {
      return orgSpecificConfig
    }
  }

  return typeConfigValue
}

export const getContextualHelp = (pass: Pass, tokenParsed: IKeycloakToken | undefined, orgs: UserOrganisationSummary[] | null, configs: IReferenceDataSummary | null): OrganisationConfig => {
  /*
  if search user
    lookup org that has been opened to find out what org type it is
    lookup custom config
    lookup config type
  else id_admin
    if custom_config
      use this
    else static config
  */

  const emptyConfig: OrganisationConfig = {
    description: '',
    card_format: {
      description: '',
      cards: []
    },
    access_area: {
      description: '',
      areas: []
    },
    issue_date:{
      description: ''
    }
  }

  const isSearchUser = hasGroups(tokenParsed, SEARCH_USER)
  const isAdminUser = hasGroups(tokenParsed, ID_ADMIN)

  if (!configs || !pass || !pass.organisation_type || !pass.organisation_code) {
    return emptyConfig
  }

  // Both types of user get general static config from the same place
  const organisationTypeConfig = configs[pass.organisation_type]
  // Search users get custom config from the config object as they get all custom configs and static configs back
  const searchUserCustomConfig = configs[pass.organisation_code]
  // ID ADMIN users get custom config from their org object as they can only search data belonging to their organisation
  const idAdminCustomConfig = orgs && orgs.length > 0 && orgs[0].custom_config
    ? orgs[0].custom_config
    : undefined

  if (!isSearchUser && !isAdminUser) {
    return organisationTypeConfig
  }

  const finalConfig = {}
  _.assignWith(finalConfig, organisationTypeConfig, configMerger)

  if (isSearchUser && searchUserCustomConfig) {
    _.assignWith(finalConfig, searchUserCustomConfig, configMerger)
  } else if (isAdminUser && idAdminCustomConfig) {
    _.assignWith(finalConfig, idAdminCustomConfig, configMerger)
  }

  return finalConfig
}


export default {
  ensureIsArray,
  requestPassHolder,
  requestLinkedProfiles,
  exportPassHolder,
  getPassHolderStatus,
  requestCompare,
  getContextualHelp,
  contextHelpAvailable
}
