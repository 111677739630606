export const ROLE_PREFIX = 'aphids'

export const IMPORT = `${ROLE_PREFIX}-import`
export const IMPORT_REVIEW = `${ROLE_PREFIX}-import-review`
export const IMPORT_DOWNLOAD = `${ROLE_PREFIX}-import-download`
export const BULK_SEARCH = `${ROLE_PREFIX}-bulk-search`
export const SEARCH = `${ROLE_PREFIX}-search`
export const SEARCH_UNJUSTIFIED = `${ROLE_PREFIX}-search-unjustified`
export const AUDIT_SEARCH = `${ROLE_PREFIX}-audit-search`
export const MI_VIEW = `${ROLE_PREFIX}-mi-view`
export const BULK_EXPORT = `${ROLE_PREFIX}-bulk-export`
export const BULK_EXPORT_REVIEW = `${ROLE_PREFIX}-bulk-export-review`
export const CREATE_ACCOUNT_REQUESTOR = `${ROLE_PREFIX}-create-account-requestor`
export const REMOVE_ACCOUNT_REQUESTOR = `${ROLE_PREFIX}-remove-account-requestor`
export const CHANGE_OTP_DEVICE_REQUESTOR = `${ROLE_PREFIX}-change-otp-device-requestor`
export const LINKED_PROFILE = `${ROLE_PREFIX}-linked-profile`
export const CRC_VIEW = `${ROLE_PREFIX}-crc-view`
export const RCRC_CONSUMER = `${ROLE_PREFIX}-rcrc-consumer`