import { DataSourceType, PassHolderSearchSummary, PassHolderSummary } from '../../../../types/api'
import { Pass, Person } from '../../../../types/PassHolder'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { fetchPassHolder, fetchLinkedProfiles, fetchCompareProfiles } from './thunks'
import { saveProfile, unsaveProfile } from '../../../SavedProfile/state/savedProfile'

export enum FromLocation {
  search = 'search',
  bulkSearch = 'bulkSearch',
  issues = 'issues',
  linkedProfiles = 'linkedProfiles',
  compare = 'compare'
}

export type MatchingTerm = {
  key: string
  value: string
}

export type LinkedProfilesState = {
  records: PassHolderSearchSummary[]
  matchingTerms: MatchingTerm[]
  originalMatchingTerms: MatchingTerm[]
  isFetching: boolean
  currentPage: number
  pageSize: number
  hasError: boolean
  totalRecords: number
}

export type PassHolderState = {
  linkedProfiles: LinkedProfilesState
  comparingProfile: PassHolderSummary | null
  passHolderId: string | null
  isFetching: boolean
  aphids_record_reference: string | null
  application_completion_date: string | null
  application_id: string | null
  application_status: string | null
  record_reference: string | null
  iata: string | null
  icao: string | null
  job_title: string | null
  person: Person | null
  pass: Pass | null
  hasError: boolean
  source_type: DataSourceType | null
  PROCESSING_timestamp: string | null
  saved_id: string | null
  fromLocation: FromLocation | null
  crc_last_check_date: string | null
  site_location: string | null
}

export const initalLinkedProfilesState: LinkedProfilesState = {
  isFetching: false,
  pageSize: 10,
  hasError: false,
  records: [],
  matchingTerms: [],
  originalMatchingTerms: [],
  currentPage: 1,
  totalRecords: 0
}

export const initialState: PassHolderState = {
  linkedProfiles: initalLinkedProfilesState,
  comparingProfile: null,
  passHolderId: null,
  PROCESSING_timestamp: null,
  isFetching: false,
  aphids_record_reference: null,
  record_reference: null,
  iata: null,
  icao: null,
  job_title: null,
  person: null,
  pass: null,
  application_completion_date: null,
  application_id: null,
  application_status: null,
  hasError: false,
  source_type: null,
  saved_id: null,
  fromLocation: null,
  crc_last_check_date: null,
  site_location: null
}

export const PASSHOLDER = 'PassHolder'

const slice = createSlice({
  name: PASSHOLDER,
  initialState: initialState,
  reducers: {
    reset() {
      return initialState
    },
    setPassHolderFromLocation(state, action: PayloadAction<FromLocation>) {
      state.fromLocation = action.payload
    },
    removeCompare(state) {
      state.comparingProfile = null
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPassHolder.pending, (state, action) => {
      state.passHolderId = action.meta.arg || initialState.passHolderId
      state.isFetching = true
    })
    builder.addCase(fetchLinkedProfiles.pending, (state) => {
      state.linkedProfiles.isFetching = true
    })
    builder.addCase(fetchLinkedProfiles.rejected, (state) => {
      const linkedProfiles: LinkedProfilesState = {
        ...initalLinkedProfilesState,
        hasError: true,
        originalMatchingTerms: state.linkedProfiles.originalMatchingTerms
      }

      state.linkedProfiles = linkedProfiles;
    })
    builder.addCase(fetchLinkedProfiles.fulfilled, (state, action) => {
      const {
        page,
        pageSize,
        result,
        filter,
        appliedFilters,
        totalRecords
      } = action.payload

      const safeFilter = filter ? filter : {}
      const matchingTerms: MatchingTerm[] = Object.keys(safeFilter)
        .flatMap(key => (
          safeFilter[key]
            .flatMap(value => ({
              key,
              value
            }))
        ))

      const originalMatchingTerms = appliedFilters ? state.linkedProfiles.originalMatchingTerms : matchingTerms
      const usingMatchingTerms = appliedFilters ? appliedFilters : matchingTerms
      state.linkedProfiles = {
        isFetching: false,
        pageSize,
        currentPage: page,
        records: result,
        hasError: false,
        matchingTerms: usingMatchingTerms,
        originalMatchingTerms,
        totalRecords
      }
    })
    builder.addCase(saveProfile.fulfilled, (state, action) => {
      const { personId } = action.meta.arg
      if (personId === state.aphids_record_reference) {
        state.saved_id = action.payload.id || initialState.saved_id
      }
      if (personId === state.comparingProfile?.aphids_record_reference) {
        state.comparingProfile.saved_id = action.payload.id || initialState.comparingProfile?.saved_id
      }
    })
    builder.addCase(fetchCompareProfiles.fulfilled, (state, action) => {
      state.comparingProfile = action.payload
    })

    builder.addCase(fetchCompareProfiles.pending, (state) => {
      state.comparingProfile = null
    })
    builder.addCase(unsaveProfile.fulfilled, (state, action) => {
      const { personId } = action.meta.arg
      if (personId === state.aphids_record_reference) {
        state.saved_id = initialState.saved_id
      }
      if (personId === state.comparingProfile?.aphids_record_reference) {
        state.comparingProfile.saved_id = initialState.comparingProfile?.saved_id
      }
    })
    builder.addCase(fetchPassHolder.fulfilled, (state, action) => {
      const {
        PROCESSING_timestamp,
        iata,
        icao,
        job_title,
        pass,
        person,
        aphids_record_reference,
        application_id,
        application_completion_date,
        application_status,
        record_reference,
        source_type,
        saved_id,
        crc_last_check_date,
        site_location
      } = action.payload

      return {
        ...state,
        linkedProfiles: initalLinkedProfilesState,
        isFetching: false,
        hasError: false,
        PROCESSING_timestamp: PROCESSING_timestamp || initialState.PROCESSING_timestamp,
        iata: iata || initialState.iata,
        icao: icao || initialState.icao,
        job_title: job_title || initialState.job_title,
        pass: pass || initialState.pass,
        person: person || initialState.person,
        aphids_record_reference: aphids_record_reference || initialState.aphids_record_reference,
        application_id: application_id || initialState.application_id,
        application_completion_date: application_completion_date || initialState.application_completion_date,
        application_status: application_status || initialState.application_status,
        record_reference: record_reference || initialState.record_reference,
        source_type: source_type || initialState.source_type,
        saved_id: saved_id || initialState.saved_id,
        crc_last_check_date: crc_last_check_date || initialState.crc_last_check_date,
        site_location: site_location || initialState.site_location
      }
    })
    builder.addCase(fetchPassHolder.rejected, () => {
      return {
        ...initialState,
        hasError: true
      }
    })
  }
})

export * from './thunks'

export const {
  reset,
  setPassHolderFromLocation
} = slice.actions

export default slice.reducer
