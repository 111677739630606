/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import GovUKCell from './GovUKCell'
import { Header } from '../../types/table'

export type ExpandedRowCreator = (data: any) => JSX.Element

type GovUKRowProps = {
  data: any
  index: number
  expanded?: boolean
  headers: Header[]
  className?: string
  expandedRowCreator?: ExpandedRowCreator
}

const GovUKRow = ({
  data,
  index,
  expanded = false,
  headers,
  className = '',
  expandedRowCreator = () => <></>
}: GovUKRowProps) => {
  return (
    <>
      <tr className={className}>
        {
          headers.map((header: any, columnIndex: number) => {

            const renderer = typeof header.renderer === 'function'
              ? header.renderer
              : () => data[header.data]

            const cellClassName = typeof header.className === 'function'
              ? header.className(data, index, columnIndex)
              : header.className

            return (
              <GovUKCell
                key={`cell-${index}-${columnIndex}`}
                content={renderer(data, index, columnIndex)}
                extraClasses={cellClassName}
              />
            )
          })
        }
      </tr>
      {
        expanded && expandedRowCreator(data)
      }
    </>
  )
}

export default GovUKRow
