import { AuthClientInitOptions, AuthClientEvent } from '@react-keycloak/core'
import { ReactKeycloakProvider } from '@react-keycloak/web'
import Keycloak from 'keycloak-js'
import React from 'react'
import Routing from './Routing'
import { addHeadersToAllRequests } from './services/request'
import authService from './services/authentication'
import { noConfigLayout } from './components/Layouts'
import { useAppSelector } from './store'

const AuthenticationProvider = () => {

  const { config } = useAppSelector((state) => state.config)

  if (!config) {
    return noConfigLayout()
  }

  const keycloak = Keycloak(config)

  const keycloakInitOptions: AuthClientInitOptions = {
    flow: 'hybrid',
    onLoad: 'check-sso',
    checkLoginIframe: false
  }

  const onKeycloakEvent = (event: AuthClientEvent) => {
    if (event === 'onAuthRefreshError') {
      authService.logout()
      keycloak.logout({
        redirectUri: window.location.origin
      })
    }
  }

  addHeadersToAllRequests(keycloak)

  return (
    <ReactKeycloakProvider
      initOptions={keycloakInitOptions}
      authClient={keycloak}
      onEvent={onKeycloakEvent}>
      <Routing />
    </ReactKeycloakProvider>
  )
}

export default AuthenticationProvider
