import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import React from 'react'
import ReactDOM from 'react-dom'
import { store } from './store'
import './library'
import '@reach/skip-nav/styles.css'
import './icons/icons.css'
import './sass/application.scss'
import axios from 'axios'
import { getWithBackoff } from './services/request'
import { setConfig, setConfigError } from './modules/Authorisation/state/config'
import { setHealth } from './modules/Authorisation/state/health'
import App from './App'

const configErrorMessage = 'The application configuration could not be fetched and the maximum attempts exceeded.'
const healthErrorMessage = 'The application health check could not be fetched and the maximum attempts exceeded'

const getApplicationConfiguration = async () => {
  axios
    .get('/api/v1/config/portal')
    .then((response) => {
      const config = response.data.data
      store.dispatch(setConfig(config))
    })
    .catch((error) => {
      onFail('The application configuration could not be fetched.')
      throw error
    })
}

const getHealthInformation = async () => {
  axios
    .get('/health')
    .then((response) => {
      const health = response.data.data
      store.dispatch(setHealth(health))
    })
    .catch((error) => {
      onFail('The application health check could not be fetched.')
      throw error
    })
}

const onFail = (errorMessage: string) => {
  store.dispatch(setConfigError(errorMessage))
}

getWithBackoff(getApplicationConfiguration, onFail, configErrorMessage)
getWithBackoff(getHealthInformation, onFail, healthErrorMessage)

if (process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'test') {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const axe = require('@axe-core/react')
  axe(React, ReactDOM, 1000)
}

ReactDOM.render(<App />, document.getElementById('root'))
