import axios from 'axios';
import {
  SupportRequest,
  ApiSuccessResult,
  FeedbackConfirmation,
  SupportFeedbackType
} from '../../types/api';

/*
  supportRequestApiPath determines how service requests are created in ServiceNow
    - "true"  Incident API will be used
    - "false" Request API will be used
*/
const supportRequestApiPath = () =>
  process.env.REACT_APP_CREATE_SUPPORT_REQUESTS_AS_INCIDENTS === 'true'
    ? '/api/v1/support/feedback-incident'
    : '/api/v1/support/feedback';


//////////////////////
// Incident Request //
//////////////////////

const submitSupportIssueRequestFeedback = async (
  type: SupportFeedbackType,
  text: string
) => {
  // Incidents should always use the incident path
  const url = `/api/v1/support/feedback-incident/issue`

  const result = await axios.post<ApiSuccessResult<FeedbackConfirmation>>(url, {
    type,
    text
  })

  return result.data;
};

//////////////////////////////
// General Feedback Request //
//////////////////////////////

const submitGeneralFeedback = async (
  score: number,
  text: string
): Promise<ApiSuccessResult<FeedbackConfirmation>> => {
  // General feedback does not integrate with ServiceNow so the path does not matter
  const url = `${supportRequestApiPath()}/general`;

  const result = await axios.post<ApiSuccessResult<FeedbackConfirmation>>(url, {
    score,
    text
  })

  return result.data;
};

///////////////////////////
// Accessibility Request //
///////////////////////////

const submitAccessibilityFeedback = async (
  location: string,
  text: string,
  replyWanted: boolean
) => {
  // Accessibility does not integrate with ServiceNow so the path does not matter
  const url = `${supportRequestApiPath()}/accessibility`;

  const result = await axios.post<ApiSuccessResult<FeedbackConfirmation>>(url, {
    location,
    text,
    replyWanted
  })

  return result.data;
};

//////////////////////
// Support Requests //
//////////////////////

const submitSupportAccessFeedback = async (
  organisation: string,
  reason: string
) => {
  const url = `${supportRequestApiPath()}/access`;

  const result = await axios.post<ApiSuccessResult<FeedbackConfirmation>>(url, {
    organisation,
    text: reason
  })

  return result.data;
};

const submitOtherRequestFeedback = async (
  type: SupportFeedbackType,
  text: string
) => {
  const url = `${supportRequestApiPath()}/request`;

  const result = await axios.post<ApiSuccessResult<FeedbackConfirmation>>(url, {
    type,
    text
  })

  return result.data;
};

const submitCreateAccountRequestFeedback = async (content: SupportRequest) => {
  const url = `${supportRequestApiPath()}/account/create`;

  const result = await axios.post<ApiSuccessResult<FeedbackConfirmation>>(url, {
    content
  })

  return result.data;
};

const submitRemoveAccountRequestFeedback = async (content: SupportRequest) => {
  const url = `${supportRequestApiPath()}/account/remove`;

  const result = await axios.post<ApiSuccessResult<FeedbackConfirmation>>(url, {
    content
  })

  return result.data;
};

const submitChangeOtpRequestFeedback = async (content: SupportRequest) => {
  const url = `${supportRequestApiPath()}/account/otp`;

  const result = await axios.post<ApiSuccessResult<FeedbackConfirmation>>(url, {
    content
  })

  return result.data;
};

export default {
  submitGeneralFeedback,
  submitAccessibilityFeedback,
  submitSupportIssueRequestFeedback,
  submitSupportAccessFeedback,
  submitCreateAccountRequestFeedback,
  submitRemoveAccountRequestFeedback,
  submitChangeOtpRequestFeedback,
  submitOtherRequestFeedback
};
