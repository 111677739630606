import { useKeycloak } from '@react-keycloak/web'
import { IKeycloakToken } from '../services/token'

const useAuthentication = () => {

  let token: string | undefined
  let tokenParsed: IKeycloakToken | undefined
  let keycloak: Keycloak.KeycloakInstance | undefined
  let initialized = false

  try {
    const kc = useKeycloak()

    keycloak = kc.keycloak
    initialized = kc.initialized

    token = keycloak && (keycloak.idToken)
    tokenParsed = keycloak && (keycloak.idTokenParsed as IKeycloakToken)
  }
  catch {
    keycloak = undefined
    tokenParsed = undefined
  }

  const login = () => {
    if (keycloak) {
      keycloak.login()
    }
  }

  const logout = () => {
    if (keycloak) {
      keycloak.logout()
    }
  }

  const updateToken = (minValiditySeconds: number) => {
    if (keycloak) {
      keycloak.updateToken(minValiditySeconds)
    }
  }

  return {
    authenticated: !!keycloak?.authenticated,
    initialized,
    keycloak,
    login,
    logout,
    token,
    tokenParsed,
    updateToken
  }
}

export { useAuthentication }