import React from 'react'
import { Navigate } from 'react-router-dom'
import Welcome from './components/Welcome'
import { hasGroups, hasRoles, isAccountConfigurationValid } from './services/token'
import { useAuthentication } from './hooks/authentication'
import { ID_ADMIN } from './constants/groups'
import { SEARCH, SEARCH_UNJUSTIFIED, AUDIT_SEARCH, BULK_SEARCH, MI_VIEW, RCRC_CONSUMER } from './constants/roles'
import { useAppSelector, useAppDispatch } from './store'
import { getUserOrganisations } from './modules/Organisation/state/information'
import { getUserProfile } from './modules/Authorisation/state/user'
import {
  errorLayout,
  unauthenticatedLayout,
  loadingLayout,
  termsLayout,
  authenticatedLayout,
  noOrganisationLayout,
  noConfigLayout,
  ipRestrictedLayout
} from './components/Layouts'

const Routing = () => {
  const dispatch = useAppDispatch()

  const { tokenParsed, authenticated } = useAuthentication()

  const { config, error: configError } = useAppSelector((state) => state.config)
  const { userProfile } = useAppSelector((state) => state.user)

  const {
    error: orgError,
    orgs: organisations
  } = useAppSelector((state) => state.organisations.information)

  const getDefaultComponent = () => {
    if (hasGroups(tokenParsed, ID_ADMIN)) {
      return <Navigate to='upload/history' />
    }
    if (hasRoles(tokenParsed, SEARCH, SEARCH_UNJUSTIFIED)) {
      return <Navigate to='search' />
    }
    if (hasRoles(tokenParsed, BULK_SEARCH)) {
      return <Navigate to='bulk-search' />
    }
    if (hasRoles(tokenParsed, AUDIT_SEARCH)) {
      return <Navigate to='audits/search' />
    }
    if (hasRoles(tokenParsed, MI_VIEW)) {
      return <Navigate to='reports/search' />
    }
    if (hasRoles(tokenParsed, RCRC_CONSUMER)) {
      return <Navigate to='repeatcrc' />
    }
    return <Welcome />
  }

  if (orgError) {
    if (orgError.statusCode == 403 && orgError.message == 'IP Address outside of accepted range')
      return ipRestrictedLayout()
    else
      return errorLayout(orgError.message)
  }

  if (configError) {
    return errorLayout(configError)
  }

  if (!config) {
    return noConfigLayout()
  }

  const isAccountConfigValid = isAccountConfigurationValid(tokenParsed)
  let layout = unauthenticatedLayout(isAccountConfigValid && userProfile?.hasAcceptedTerms)

  if (authenticated) {
    if (!organisations) {
      dispatch(getUserOrganisations())
      layout = loadingLayout(isAccountConfigValid && userProfile?.hasAcceptedTerms)
    } else {

      if (userProfile === null) {
        dispatch(getUserProfile())
        layout = loadingLayout(true)
      } else if (!userProfile.hasAcceptedTerms) {
        layout = termsLayout()
      } else {
        layout = isAccountConfigValid
          ? authenticatedLayout(userProfile?.hasAcceptedTerms, getDefaultComponent())
          : noOrganisationLayout(userProfile?.hasAcceptedTerms)
      }
    }
  }

  return layout
}

export default Routing
