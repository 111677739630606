import { combineReducers } from 'redux'
import results from './results'
import suggestions from './suggestions'
import uploads from './upload'
import justification from './justification'

export default combineReducers({
  justification,
  results,
  suggestions,
  uploads
})
